/**
 * Copy Text
 * @description : Copy to clipboard functionality for link sharing
 */

(function () {
    var copyTextEls = document.querySelectorAll('.js-copy-text');

    for (var i = 0; i < copyTextEls.length; i++) {
        (function (copyTextEl) {
            var valueEl = copyTextEl.querySelector('.js-copy-text__value');
            var buttonEl = copyTextEl.querySelector('.js-copy-text__button');
            var messageEl = copyTextEl.querySelector('.js-copy-text__message');
            var messageTextEl = copyTextEl.querySelector('.js-copy-text__message-text');

            buttonEl.addEventListener('click', function () {
                valueEl.select();
                if (document.execCommand('copy')) {
                    messageTextEl.textContent = 'Copied';
                    messageEl.removeAttribute('hidden');
                    setTimeout(function () {
                        messageEl.setAttribute('hidden', true);
                    }, 2000);
                }
            });
        })(copyTextEls[i]);
    }

})();